import React from 'react'
import { useLangContext } from '../context/lang.context'
import { sCenteredSpaced } from '../style'
import Button from './Button'

const ButtonForm = ({ data }) => {
  const { lang } = useLangContext()
  return (
    <div css={sCenteredSpaced}>
      <Button glow link={data.link} action={data.action}>
        {data.text[lang]}
      </Button>
    </div>
  )
}

export default ButtonForm
