import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main, buttonBack } from '../content/transport'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Tiles from '../components/Tiles'
import Flag from '../assets/Flag'
import BreifcaseIcon from '../assets/BreifcaseIcon'
import TruckIcon from '../assets/TruckIcon'
import ButtonForm from '../components/ButtonForm'
import TransportAdditionalText from '../sections/transport/TransportAdditionalText'

const Transport = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  const { image, imageCountries, imageIndustries, imageTransportTypes } =
    useStaticQuery(graphql`
      {
        image: file(absolutePath: { regex: "/images/intros/transport.jpg/" }) {
          publicURL
          childImageSharp {
            gatsbyImageData(
              quality: 80
              placeholder: DOMINANT_COLOR
              formats: [WEBP, AVIF]
            )
          }
        }
        imageCountries: file(
          absolutePath: {
            regex: "/images/intros/transport-miedzynarodowy.jpg/"
          }
        ) {
          childImageSharp {
            gatsbyImageData(width: 480, quality: 80, formats: [WEBP, AVIF])
          }
        }
        imageIndustries: file(
          absolutePath: { regex: "/images/intros/transport-branze.jpg/" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 480, quality: 80, formats: [WEBP, AVIF])
          }
        }
        imageTransportTypes: file(
          absolutePath: { regex: "/images/intros/transport-rodzaje.jpg/" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 480, quality: 80, formats: [WEBP, AVIF])
          }
        }
      }
    `)

  const links = [
    {
      slug: 'transport-miedzynarodowy',
      name:
        lang === 'en' ? 'International Transport' : 'Transport Międzynarodowy',
      image: imageCountries,
      icon: () => <Flag />,
    },
    {
      slug: 'transport-branze',
      name: lang === 'en' ? 'Transport for Industries' : 'Transport dla Branż',
      image: imageIndustries,
      icon: () => <BreifcaseIcon />,
    },
    {
      slug: 'transport-rodzaje',
      name: lang === 'en' ? 'Transport Services' : 'Usługi/Rodzaje Transportu',
      image: imageTransportTypes,
      icon: () => <TruckIcon />,
    },
  ]

  const buttonSecond = {
    text: {
      pl: 'Zleć transport',
      en: 'Quote transport',
    },
    action: () => setFormOpen(true),
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/transport.jpg'}
        url={seo.url}
      />
      <Intro
        data={{ ...intro, buttonSecond }}
        image={image.childImageSharp}
        h={1}
      />
      <Main data={main} />
      <TransportAdditionalText />
      <Tiles data={links} large />
      <ButtonForm data={buttonBack} />
    </Layout>
  )
}

export default Transport
