export const seo = {
  url: 'transport',
  title: {
    pl: 'Transport | Usługi | Rodzaje | Kraje',
    en: 'Transport | Services | Types | Countries',
  },
  desc: {
    pl: 'Poznaj rodzaje transportu, kompleksowe usługi transportowe w różnych branżach. Sprawdź jak wygląda transport w krajach Europy.',
    en: 'Learn about the types of transport, comprehensive transport services in various industries. Check what transport looks like in European countries.',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Transport',
    en: 'Transport',
  },
  desc: {
    pl: 'Poznaj rodzaje transportu, kompleksowe usługi transportowe w różnych branżach. Sprawdź jak wygląda transport w krajach Europy.',
    en: 'Learn about the types of transport, comprehensive transport services in various industries. Check what transport looks like in European countries.',
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Transport Omida Logistics - polska firma transportowa. Skorzystaj z usług TSL doświadczonego Partnera Logistycznego.',
        en: 'Transport Omida Logistics - Polish transport company. Take advantage of TSL services and deliver your transport to the destination.',
      },
      texts: [
        {
          pl: '<div>Omida Logistics to polska firma transportowa. Zajmujemy się przewozem ładunków transportem drogowym, kolejowym i intermodalnym. W ramach naszych usług organizujemy multum różnych rodzajów transportu, m.in. <strong>transport całopojazdowy</strong> oraz <strong>transport drobnicowy</strong>.</div>',
          en: '<div> Omida Logistics is a Polish transport company. We deal with the transport of goods by road, rail and intermodal transport. As part of our services, we organize a multitude of different types of transport, incl. <strong>full truckload transport</strong> and <strong> less than truckload transport </strong>. </div>',
        },
        {
          pl: '<div>Nasze oddziały znajdują się w ponad 30 miejscowościach w całej Polsce. Od 2019 posiadamy <strong>magazyn klasy A pod Warszawą</strong>, obsługując całość procesów logistycznych naszych Klientów. Naszą mocną stroną jest transport całopojazdowy <strong>FTL po całej Europie</strong>. To w tym jesteśmy najlepsi!</div>',
          en: '<div> Our branches are located in over 30 cities all over Poland. From 2019, we have a <strong> A-class warehouse near Warsaw </strong>, handling all logistics processes of our clients. Our strength is <strong> FTL full truckload transport across Europe </strong>. This is where we are the best! </div>',
        },
        {
          pl: '<div>Szukasz transportu towaru w Europie? Wybierz Omida Logistics. Stawiamy na <strong>stały kontakt opiekuna z klientem i specjalizację zespołu</strong> w obsłudze danej branży!</div>',
          en: "<div> Looking to transport goods in Europe? Choose Omida Logistics. We focus on <strong> constant contact between the supervisor and the client and the team's specialization </strong> in serving a given industry! </div>",
        },
      ],
    },
    {
      headline: {
        pl: 'Podział transportu na rodzaje, usługi i branże',
        en: 'Division of transport into types, services and industries',
      },
      texts: [
        {
          pl: '<div>Transport można podzielić ze względu na typy związane z różnymi usługami. Najbardziej popularnymi rodzajami transportu jest transport FTL oraz LTL. Transport może być również skategoryzowany na podstawie konkretnych <strong>gałęzi przemysłu i produktów</strong>, które są w nich wytwarzane lub branż, w których są one wykorzystywane. Przeczytaj także o <strong>transporcie w różnych państwach</strong>. Skorzystaj z poniższych przycisków, aby przejść do odpowiednich podstron.</div>',
          en: '<div>Transportation can be divided into types related to different services. TThe most popular types of transport are FTL and LTL. Transport can also be categorized based on <strong>specific industries and the products</strong> they manufacture or the industries in which they are used. Also read about <strong>transport in different countries</strong>. Use the buttons below to go to the relevant subpages.</div>',
        },
      ],
    },
  ],
}

export const buttonBack = {
  text: {
    pl: 'Wyceń transport',
    en: 'Quote a transport',
  },
  link: '/wycena-transportu?form=open/',
}
